import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Role} from "../../shared/models/user";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /**
  * Used for obtaining the role of interface
  * @type {Role}
  */
  roles = Role;
  loadedForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userRole: Role | undefined;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { 
  
    this.authService.currentUser$.subscribe(value => {
      if(value) this.userRole = value.role});
  }

  ngOnInit(): void {
    this.loadedForm$.next(true)
  }

  profileIcon(): boolean {
    return this.authService.currentUserValue !== null;
  };

  homePath(): string {
    switch (this.authService.currentUserValue?.role) {
      case Role.STATION:
        return '/station';
      case Role.COMMITTEE:
        return '/committee';
      case Role.COUNCIL:
        return '/council';
      default:
        return '/';
    }
  }

  homeIcon(): boolean {
    const excludePath = ['/station/', '/committee/', '/council/'];
    return excludePath.some(el => window.location.pathname.includes(el));
  }

  logout() {
    this.authService.logout();
  }

  navigateTo(link: string): void {
    this.router.navigate([link]);
  }

}
