<nav class="pagination-wrapper" aria-label="Esempio di navigazione con page changer">
  <!-- begin: pagination -->
  <ul class="pagination">
    <!-- begin: previous section -->
    <li class="page-item" [ngClass]="getClassOfButton('prev')">
      <a class="page-link cursor-pointer" (click)="prev()" >
        <svg class="icon icon-primary"><use href="assets/bootstrap-italia/svg/sprites.svg#it-chevron-left"></use></svg>
        <span class="visually-hidden">Pagina precedente</span>
      </a>
    </li>
    <!-- end: previous section -->
    <!-- begin: number section -->
    <ng-container *ngFor="let page of pagesValue">
      <li class="page-item page-number-item" [ngClass]="getClassOfPage(page)">
        <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
      </li>
    </ng-container>
    <!-- end: number section -->
    <!-- begin: next section -->
    <li class="page-item" [ngClass]="getClassOfButton('next')">
      <a class="page-link cursor-pointer" (click)="next()" >
        <span class="visually-hidden">Pagina successiva</span>
        <svg class="icon icon-primary"><use href="assets/bootstrap-italia/svg/sprites.svg#it-chevron-right"></use></svg>
      </a>
    </li>
    <!-- end: next section -->
  </ul>
  <!-- end: Pagination -->
  <!-- start: PerPageOption -->
  <div class="dropdown">
    <!-- begin: Displayed Current PerPageOption -->
    <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="pagerChanger" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Salta alla pagina">
      {{paginationValue.perPage}}/{{"PAGE_LABEL" | translate}}
      <svg class="icon icon-primary icon-sm"><use href="assets/bootstrap-italia/svg/sprites.svg#it-expand"></use></svg>
    </a>
    <!-- end: Displayed Current PerPageOption -->
    <!-- begin: PerPageOption Dropdown -->
    <div class="dropdown-menu pagination-list" aria-labelledby="pagerChanger">
      <div class="link-list-wrapper">
        <ul class="link-list">
          <!-- begin: PerPageOption List -->
          <ng-container *ngFor="let option of perPageOptions">
            <li><a class="list-item" [ngClass]="option == paginationValue.perPage ? 'active' : 'dropdown-item'" (click)="changePerPage(option)">{{option}}/{{"PAGE_LABEL" | translate}}</a></li>
          </ng-container>
          <!-- end: PerPageOption List -->
        </ul>
      </div>
    </div>
    <!-- end: PerPageOption Dropdown -->
  </div>
  <!-- end: PerPageOption -->
</nav>
