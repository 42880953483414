import Swal from 'sweetalert2';

export const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-outline-primary m-2'
  },
  showCancelButton: true,
  confirmButtonText: 'Conferma',
  cancelButtonText: 'Annulla',
  reverseButtons: true,
  buttonsStyling: false
})

export const confirmSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-outline-primary m-2'
  },
  showCancelButton: false,
  confirmButtonText: 'Conferma',
  cancelButtonText: 'Annulla',
  reverseButtons: false,
  buttonsStyling: false
})


export const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});


export const errorSwal = Swal.mixin({
  icon: 'error',
})