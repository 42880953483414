<nav class="breadcrumb-container" aria-label="Percorso di navigazione">
  <ol class="breadcrumb">
    <ng-container *ngFor="let breadcrumb of breadcrumbsArray">
      <li class="breadcrumb-item">
        <a *ngIf="breadcrumb.icon == 'back'">
          <svg class="align-self-center icon icon-xs icon-primary">
            <use href="assets/bootstrap-italia/svg/sprites.svg#it-arrow-left"></use>
          </svg>
        </a>
        <a [href]="breadcrumb.link"> {{breadcrumb.label | translate}}</a>
        <span class="separator">&gt;</span>
      </li>
    </ng-container>
  </ol>
</nav>
