<table class="table projects-table" aria-describedby="project data table">
  <thead>
    <tr valign="middle">
      <!-- begin: show data for council -->
      <ng-container>
        <ng-container *ngFor="let item of columnDefault">
          <th class="col">
            <div class="col-content">
              <span [ngClass]="fontSizeSmall && council == 'true' ? 'fs-14' : ''" class="col-text">
                {{item.label}}</span>
              <div class="svg-wrapper">
                <svg class="cursor-pointer svg-icon" (click)="changeHref(item.value)" [id]="item.value">
                  <use [attr.href]="clickedIcon === 'Scadenze' ? newHref : iconHref"></use>
                </svg>
              </div>
            </div>
          </th>
        </ng-container>
      </ng-container>
      <!-- end: show data for council -->

      <th class="col" *ngIf="userRole !== roles.STATION"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of dataSources">
      <tr>
        <ng-container *ngIf="!council || council == 'false'">
          <td class="fw-600 table-link title-column" (click)="navigateTo('projects/', data.Id);">
            {{data.Name}}
          </td>
        </ng-container>
        <ng-container *ngIf="council == 'true'">
          <td [ngClass]="fontSizeSmall ? 'fs-14 fw-400' : ''" class="fw-600 title-column">
            {{data.Name}}
          </td>
        </ng-container>
        <td  *ngIf="council == 'true'">
          <div class="fs-14">
            {{data.Procedure.Name}}
          </div>
        </td>

        <td>
          <span [ngClass]="{ 'fs-14': council === 'true', 'fs-16': council !== 'true', 'color-red': getStatusLabel(data.TaskId).isRed }">
            {{ getStatusLabel(data.TaskId).label | translate }}
          </span>
          <!-- <ng-container *ngIf="!data.DayLeft; else DayLeft">Indefinita</ng-container> -->
          <ng-template #DayLeft>
            <ng-container *ngIf="data.ExpireTask || data.TaskId == 'FINE_PROGETTO'; else noDays">
              <span *ngIf="data.TaskId != 'FINE_PROGETTO'"
                [ngClass]="getNumberOfDays(data.ExpireTask) < 5 ? 'color-red' : ''"> {{data.ExpireTask | date: 'dd/MM/YYYY'}}</span>
              <span *ngIf="data.TaskId === 'FINE_PROGETTO'">Concluso</span>
            </ng-container>
            <ng-template #noDays>Indefinita</ng-template>
          </ng-template>

        </td>
     
        <!-- begin: show data for council -->
        <ng-container *ngIf="council == 'true'">
          <td>
            <div class="fs-14">
              {{data.Station.Name}}
            </div>
          </td>
          <td>
            <div class="fs-14">
              <ng-container *ngIf="data.ExpireTask || data.TaskId == 'FINE_PROGETTO'; else noDays">
                <span *ngIf="data.TaskId != 'FINE_PROGETTO'"
                  [ngClass]="getNumberOfDays(data.ExpireTask) < 5 ? 'color-red' : ''"> {{data.ExpireTask | date: 'dd/MM/YYYY'}}</span>
                <span *ngIf="data.TaskId === 'FINE_PROGETTO'">Concluso</span>
              </ng-container>
              <ng-template #noDays>Indefinita</ng-template>
            </div>
          </td>
          <td>
            <div class="fs-14">
              {{data.ProjectAmount | currency:'EUR' : 'symbol' : '1.2-2': 'it-IT'}}
            </div>
          </td>
          <td>
            <div class="fs-14">
              {{data.FundingFormat}}
            </div>
          </td>
          <td></td>
        </ng-container>
        <!-- end: show data for council -->


        <!-- <ng-container *ngIf="userRole == roles.STATION">
          <td>
            <div class="fs-16 fw-600">
              <ng-container *ngIf="data.ExpireTask || data.TaskId == 'FINE_PROGETTO'; else noDays">
                <span *ngIf="data.TaskId != 'FINE_PROGETTO'"
                  [ngClass]="getNumberOfDays(data.ExpireTask) < 5 ? 'color-red' : ''"> {{data.ExpireTask | date: 'dd/MM/YYYY'}} </span>
                <span *ngIf="data.TaskId === 'FINE_PROGETTO'">Concluso</span>
              </ng-container>
              <ng-template #noDays>Indefinita</ng-template>
            </div>
          </td>
          <td></td>
        </ng-container> -->

        <ng-container *ngIf="council == 'false'">
          <td>
            <div class="table-btn">
              <ng-container *ngIf="data.ExpireTask || data.TaskId == 'FINE_PROGETTO'; else noDays">
                <span *ngIf="data.TaskId != 'FINE_PROGETTO'"
                  [ngClass]="getNumberOfDays(data.ExpireTask) < 5 ? 'color-red' : ''"> {{data.ExpireTask | date: 'dd/MM/YYYY'}}</span>
                <span *ngIf="data.TaskId === 'FINE_PROGETTO'">Concluso</span>
              </ng-container>
              <ng-template #noDays>Indefinita</ng-template>
            </div>
          </td>
          <td></td>
        </ng-container>
      </tr>

    </ng-container>
    <ng-container *ngIf="dataSources.length == 0">
      <tr>
        <td colspan="12" class="text-center">{{"NO_DATA_FOUND" | translate}}</td>
      </tr>
    </ng-container>
  </tbody>
</table>