import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StatusLabel } from "./models/datatable";
import * as moment from 'moment';

@Component({
  selector: 'app-projects-datatables',
  templateUrl: './projects-datatables.component.html',
  styleUrls: ['./projects-datatables.component.scss']
})
export class ProjectsDatatablesComponent implements OnInit, AfterViewInit {
  clickedIcon: string = '';
  iconHref = 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-up';
  newHref = 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-down';

  @Output() reloadData: EventEmitter<any> = new EventEmitter<any>();

  /**
  * Used for getting the data from the project
  * @type {any[]}
  * @Input
  */
  @Input() dataSources: any[] = [];

  /**
  * Used for getting the first label column
  * @type {string}
  * @Input
  */
  @Input() firstColumnName: string = 'PROJECT_LABEL'


  /**
  * Used for getting the first label column
  * @type {boolean}
  * @Input
  */
  @Input() council: any;

  userRole: Role | undefined = this.authService.currentUserValue?.role;
  roles = Role;

  @Input() fontSizeSmall: boolean = false;
  /**
  * Used for activating the button for committee and hidding the state of the project
  * @type {boolean}
  * @Input
  */
  @Input() statusActive: boolean = true;

  color_label: boolean = false;
  columnDefault = [
    {
      label: 'Progetti',
      value: 'Name',
    },
    {
      label: 'Stato',
      value: 'TaskId'
    },
    {
      label: 'Scadenze',
      value: 'TaskId'
    },
  ]

  constructor(private router: Router,
    private authService: AuthService,
  ) {

  }

  ngOnInit(): void {
    if (this.council == 'true') {
      this.columnDefault = [
        {
          label: 'Progetti',
          value: 'Name',
        },
        {
          label: 'Rif. Normativo',
          value: 'Procedure.Name'
        },
        {
          label: 'Stato del procedimento',
          value: 'TaskId'
        },
        {
          label: 'Stazione appaltante',
          value: 'StationId'
        },
        {
          label: 'Scadenze',
          value: 'TaskId'
        },
        {
          label: 'Importo del Progetto',
          value: 'ProjectAmount'
        },
        {
          label: 'Forma di finanziamento',
          value: 'FundingFormat'
        },
      ];
    } else if (this.userRole === this.roles.STATION) {
      // Rimuovi l'ultimo elemento se il ruolo è STATION
      this.columnDefault.pop();
    }
  }

  ngAfterViewInit() {
  }


  getNumberOfDays(date: any) {
    const oggi = moment();
    const dataObiettivo = moment(date);
    return dataObiettivo.diff(oggi, 'days');
  }

  getClassOfDeadline(dayLeft: number): string | null {
    if (dayLeft <= 5) {
      return 'text-danger';
    }
    return null;
  }



  getStatusLabel(status: string) {
    switch (status) {
      case StatusLabel.INIZIO:
        return { label: 'PROJECT.STATUS_0_LABEL', isRed: false };
      case StatusLabel.ISTRUTTORIA:
        return { label: 'PROJECT.STATUS_1_LABEL', isRed: true };
      case StatusLabel.INTEGRAZIONE_CHIARIMENTI:
        return { label: 'PROJECT.STATUS_2_LABEL', isRed: false };
      case StatusLabel.PARERE_IN_LAVORAZIONE:
        return { label: 'PROJECT.STATUS_3_LABEL', isRed: true };
      case StatusLabel.PARERE_EMESSO:
        return { label: 'PROJECT.STATUS_4_LABEL', isRed: false };
      case StatusLabel.BIS_5TER:
        return { label: 'PROJECT.STATUS_6_LABEL', isRed: true };
      case StatusLabel.FINE_PROGETTO:
        return { label: 'PROJECT.STATUS_7_LABEL', isRed: false };
      default:
        return { label: 'PROJECT.STATUS_UNKNOWN_LABEL', isRed: false };
    }
  }
  


  navigateTo(path: string, project_id: string) {
    switch (this.authService.currentUserValue?.role) {
      case Role.STATION:
        this.router.navigate([`station/${path}${project_id}`]);
        break;
      case Role.COUNCIL:
        this.router.navigate([`council/${path}${project_id}`]);
        break;
      case Role.COMMITTEE:
        this.router.navigate([`committee/${path}${project_id}`]);
        break;
    }
  }


  changeHref(icon: string) {
    let element;
    let href = document.getElementById(icon)?.querySelector('use')?.getAttribute('href');
    element = (href as any).includes('it-arrow-up') ? { 'column': icon, 'value': 'ASC' } : { 'column': icon, 'value': 'DESC' };
    this.reloadData.emit(element);
    this.iconHref = this.iconHref === 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-up' ?
      this.newHref : 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-up';
  }
}
