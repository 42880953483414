import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./layout/auth/auth.module').then(m => m.AuthModule) },
  { path: 'station', loadChildren: () => import('./layout/station-app/station-app.module').then(m => m.StationAppModule) },
  { path: 'committee', loadChildren: () => import('./layout/committee-app/committee-app.module').then(m => m.CommitteeAppModule) },
  { path: 'council', loadChildren: () => import('./layout/council-app/council-app.module').then(m => m.CouncilAppModule) },
	{ path: '', redirectTo: "auth", pathMatch: "full" },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
