<form [formGroup]="formGroup">
	<div class="row">
		<ng-container *ngIf="loadedForm$ | async">
			<ng-container *ngFor="let form of dynamicForms">
				<ng-container *ngIf="!form.visibleConditions || form.conditions()">
					<div [class]="form.column">
						<div [ngClass]="form.type == 'checkbox' ? '' : 'form-group'">
							<label class="active" [for]="form.formControlName">{{form.label}}</label>
							<ng-container [ngSwitch]="form.type">

								<ng-container *ngSwitchCase="'currency'">
									<input type="text" [formControlName]="form.formControlName" currencyMask
										[options]="currencyMaskConfig" [placeholder]="form.placeholder"
										[class]="form.class" [required]="form.required" [id]="form.formControlName"
										style="top: 6px">
								</ng-container>

								<ng-container *ngSwitchCase="'select'">
									<label class="active" [for]="form.formControlName">{{form.label}}</label>
									<div class="select-wrapper bg-transparent">
										<select type="select" [formControlName]="form.formControlName"
											[class]="form.class" [required]="form.required" [id]="form.formControlName">
											<option value="" disabled selected> Seleziona </option>
											<option *ngFor="let option of form.option" [value]="option.value">
												{{option.label }}</option>
										</select>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'textarea'">
									<textarea [id]="form.formControlName" [class]="form.class" rows="3"
										[formControlName]="form.formControlName" [required]="form.required"></textarea>
								</ng-container>


								<!-- da rivedere -->
								<!-- <ng-container *ngSwitchCase="'file'">
									<input [type]="form.type" [formControlName]="form.formControlName"
										[class]="form.class" [required]="form.required" [id]="form.formControlName"
										style="top: 6px" (change)="onFileChange($event)">
								</ng-container> -->


								<ng-container *ngSwitchCase="'multiselect'">
									<mat-form-field appearance="fill" class="col-12">
										<mat-select [formControlName]="form.formControlName" multiple
											(selectionChange)="onSelectionChange($event)">
											<mat-option *ngFor="let item of itemSelect"
												[value]="item[form.value]">{{item[form.displayLabel]}}
												{{item[form.displayLabelOption]}}</mat-option>
										</mat-select>
									</mat-form-field>
								</ng-container>

								<ng-container *ngSwitchCase="'chiplist'">
								<mat-form-field class="example-chip-list" appearance="fill">
									<mat-chip-list #chipList aria-label="">
									  <mat-chip *ngFor="let text of texts" (removed)="remove(text)">
										{{text}}
										<button matChipRemove>
										  <mat-icon>cancel</mat-icon>
										</button>
									  </mat-chip>
									  <input placeholder="Aggiungi email.."
											 [matChipInputFor]="chipList"
											 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
											 [matChipInputAddOnBlur]="addOnBlur"
											 (matChipInputTokenEnd)="add($event)">
											 <mat-error *ngIf="isEmailInvalid">
												<span class="fs-12">Inserisci un indirizzo email valido.</span>
											  </mat-error>
									</mat-chip-list>
								  </mat-form-field>
								</ng-container>

								<ng-container *ngSwitchDefault>
									<input [type]="form.type" [formControlName]="form.formControlName"
										[placeholder]="form.placeholder" [class]="form.class" [required]="form.required"
										[id]="form.formControlName" style="top: 6px" [pattern]="form.pattern">
								</ng-container>


							</ng-container>
						</div>

					</div>
				</ng-container>

			</ng-container>
		</ng-container>
	</div>
</form>