<div *ngIf="loading" class="loading-container flex-content-center flex-center">
  <mat-progress-spinner class="m-auto mat-spinner-color" mode="indeterminate" diameter="70"></mat-progress-spinner>
  <!-- <div *ngIf="true" class="d-flex">
    <p>{{loadingMessage | async}} Messaggio</p>
    <div class="loading-dots">
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
    </div>
  </div> -->
</div>

<div class="page-body justify-content-between">
  <app-header *ngIf="header"></app-header>
  <div *ngIf="sidebar; else noSidebar" class="d-flex flex-row">
    <app-sidebar *ngIf="sidebar"></app-sidebar>
    <router-outlet></router-outlet>
  </div>
  <ng-template #noSidebar>
    <div class="d-flex flex-column full-width-container">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
  <app-footer *ngIf="footer"></app-footer>
</div>