<section class="container full-width-container d-flex justify-content-center" id="detail-project-container">
	<div class="col-12 col-lg-12 mb-3">

		<!-- begin: BREADCUMB -->
		<section class="row mt-5 mb-2" id="breadcumb-container">
			<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
		</section>
		<!-- end: BREADCUMB -->

		<!-- begin: PROJECT TITLE -->
		<section class="row" id="project-title-container">
			<div class="col-12">
				<h3>{{projectDetails?.Name}}</h3>
			</div>
		</section>
		<!-- end: PROJECT TITLE -->

		<!-- begin: PROJECT TIMELINE -->
		<hr>
		<section class="row" id="timeline-container">

			<app-stepper-icon [stepYourProjects]="timelineStep"></app-stepper-icon>
		</section>
		<!-- end: PROJECT TIMELINE -->

		<!-- begin: PROJECT BODY -->
		<section class="row " id="project-body-container">
			<!-- begin: PROJECT DIRECTORY -->
			<div class="col-7">
				<ng-container>
					<app-list-documents [documentsList]="documents" [showDirectory]="true" [projectStatus]="projectInfo"
						(refreshDocument)="getProjectInfo()"></app-list-documents>
				</ng-container>
			</div>
			<!-- end: PROJECT DIRECTORY -->

			<!-- begin: PROJECT INFO -->
			<div class="col-5">
				<app-general-info-your-projects [infoYourProject]="projectDetails" [projectStatus]="projectInfo"
					[documentsList]="documents" [stepYourProjects]="timelineStep" (reloadData)="getProjectInfo()"
					[documentId]="documentId"></app-general-info-your-projects>
			</div>
			<!-- end: PROJECT INFO -->
		</section>

		<!-- begin: PROJECT DOCUMENTS -->
		<section class="row justify-content-between" id="project-body-container">
			<div class="col-12">
				<ng-container>
					<app-list-documents [documentsList]="documents" [documentsIntegration]="documentsIntegration"
						[showDirectory]="false" [projectStatus]="projectInfo"
						(refreshDocument)="getProjectInfo()"></app-list-documents>
				</ng-container>
			</div>
		</section>
		<!-- end: PROJECT DOCUMENTS -->
	</div>

</section>