import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalendarAPI } from '../models/calendar';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private http: HttpClient) { }

  private refresh = new Subject<boolean>();

  refreshData(value: boolean) {
    this.refresh.next(value);
  }

  getRefreshData() {
    return this.refresh.asObservable();
  }

  getEvents(): Observable<any> {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event`);
  }

  postEvent(data: any): Observable<any> {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event`, data);
  }

  deleteEvent(event_id: string): Observable<any> {
    return this.http.delete(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event/${event_id}`);
  }

  editEvent(event_id: string, data: any): Observable<any> {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event/${event_id}`, data);
  }

  getEvent(event_id: string): Observable<any> {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event/${event_id}`);
  }

  getUsers(filter: string): Observable<any> {
      return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users?filter=${filter}`);
  }

  getUsersCommitte() {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users/userscommittee`);
  }

  getCommitteGroups() {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users/committee`);
  }

  validateEvent(eventId: string, data: any) {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event/${eventId}/meetingmanagement`, data);
  }

  getDataEvent(eventId: string) {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event/${eventId}/meetingmanagement`);
  }

  getAllEvents() {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_calendar}/event/allmeetingsmanagement`);
  }

}
