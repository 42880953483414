export const environment = {
	production: true,
	debug: true,
	isDevMode: true,
	wso2_apim: {
		url: "/services",
		ms_project: "projects/api/v1",
		ms_user: "user/api/v1",
		ms_calendar: "calendar/api/v1",
	},
	wso2_identity_server: {
		url: "",
		client_id: "",
		callback_url: "",
		logout_callback_url: "",
		scopes: "",
	},
	nextcloud: {
		url: "https://nextcloud.185.73.249.87.sslip.io/index.php/apps/oidc_login/oidc?login_redirect_url=/index.php/apps/files/?dir=/",
		main_directory: "Gestione_Commessa",
		group_directory:  {
			dir_1: "/EUTALIA_SRL",
			dir_2: "/CSLLPP",
			dir_3: "/GRUPPO_DI_LAVORO"
		}
	}
};
