import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { delay } from "rxjs";
import Swal from 'sweetalert2';
import { config } from '../environments/config';
import { environment } from "../environments/environment";
import { LoaderService } from './shared/helpers/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'csllpp';
  loading = false;
  header = config.header;
  sidebar = config.sidebar;
  footer = config.footer;
  currentLang: any = navigator.language.split('-')[0]

  loadingMessage = this._loading.loadingMessage.asObservable();

  constructor(
    private _loading: LoaderService,
    private translate: TranslateService,
  ) {
    // Prevent console.log from throwing errors in IE for the purposes of the demo
    if (environment.production && !environment.debug) {
      console.warn(`🚨 Console output is disabled on production!`)
      // console.log = function () { };
      console.warn = function () { };
      console.info = function () { };
			console.debug = function () { };
			console.error = function () { };
    } else if(environment.production && environment.debug) {
			// console.log = function () { };
		}

    translate.addLangs(['it', 'en']);
    translate.setDefaultLang(this.currentLang);
    translate.use(this.currentLang);
  }

  ngOnInit() {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}

export const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
