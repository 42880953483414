// Angular
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { confirmSwal } from 'src/swal';
import { toast } from "../../app.component";
import { HttpContextConfig } from "../models/http-context-config";
import { LoaderService } from './loader.service';
import { environment } from 'src/environments/environment';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig())

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.log('interceptor', request);

    // set withCredentials to true to send cookies
    // request = request.clone({
    //   withCredentials: true
    // });

    let config: HttpContextConfig = new HttpContextConfig();

    // check if the request have NO_SPINNER context
    if (request.context.get(CONFIG)) {
      config = request.context.get(CONFIG);
      if (!config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }
      if (config.isPublic) {
        // console.log("this is a public request");
        /*this.translate.get(['toast.info.100_TITLE', 'toast.info.100_TEXT']).subscribe(message =>
          toast.fire({
            title: message['toast.info.100_TITLE'],
            text: message['toast.info.100_TEXT'],
            icons: 'info'
          }
          )
        );*/
      } else {
        if(environment.isDevMode) {
          request = request.clone({
            setHeaders: {
              "X-JWT-Assertion": `${localStorage.getItem('token')}`,
            },
          });
        }
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
      }
    }

    return next.handle(request)
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.loaderService.setLoading(false, request.url);
          }
          return evt;
        })
      )
      .pipe(tap((event) => { }),
        catchError((err) => {
          this.loaderService.setLoading(false, request.url);
          if (config.isInitializer) {
            return of<any>(null);
          }
          // console.log('error', err);
          if (err.status === 401) {
            this.router.navigateByUrl('/auth/login');
          }
          if (new RegExp('[4,5]0[0-5]').test(err.status)) {
            if (err.error.Message == "REQUIRED_FOLDER_EMPTY") {
              confirmSwal.fire({
                title: "Documenti obbligatori assenti",
                html: "Sono stati riscontrati alcuni documenti obbligatori mancanti.<br>Si prega di controllarli nuovamente.",
                icon: 'warning'
              });
            } else {
              this.translate.get([`TOAST.ERROR.${err.status}_TITLE`, `TOAST.ERROR.${err.status}_TEXT`]).subscribe(message =>
                toast.fire({
                  title: message[`TOAST.ERROR.${err.status}_TITLE`],
                  text: message[`TOAST.ERROR.${err.status}_TEXT`],
                  icon: 'error'
                }
                ));
            }

          } else {
            this.translate.get(['TOAST.ERROR.general_TITLE', 'TOAST.ERROR.GENERAL_TEXT']).subscribe(message =>
              toast.fire({
                title: message['TOAST.ERROR.GENERAL_TITLE'],
                text: message['TOAST.ERROR.GENERAL_TEXT'],
                icon: 'error'
              }
              ));
          }


          return throwError(err.error);
        })
      )
  }
}
