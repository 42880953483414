export interface User {
  email: string;
  id?: number;
  role: Role;
  username: string;
}

export class User implements User {
  constructor(username: string, email: string) {
    this.username = username;
    this.email = email;
  }
}

export enum Role {
  COUNCIL = "COUNCIL",
  COMMITTEE = "COMMITTEE",
  STATION = "STATION"
}

export enum RoleNumbered {
  COUNCIL = "1",
  COMMITTEE = "2",
  STATION = "3"
}


