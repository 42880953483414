<footer class="footer">
    <div class="it-footer-main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="it-brand-wrapper">
                        <a href="#">
                            <img class="icon" src="assets/img/logos/marchio_ist.svg" alt="Logo Italia">
                            <div class="it-brand-text">
                              <div>Consiglio Superiore</div>
                              <div>dei Lavori Pubblici</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
