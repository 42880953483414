<div class="container-fluid background-image">
    <div class="container full-width-container">

        <img class="background-icon" src="assets/img/background/crain.svg" height="308" alt="crain_bg_icon">
        <div class="row text-white text-start py-5">
            <h1 class="mt-5"> <span *ngIf="councilInfo?.role == roles.COUNCIL"> Amministratore </span>
                <span *ngIf="councilInfo?.role == roles.COMMITTEE"> Comitato Speciale </span> <br>
                del Consiglio Superiore dei Lavori Pubblici
            </h1>
        </div>
        <div class="col-2 mt-4 text-center box-filter d-block"  (click)="goToUrl();">
            <div class="row">
                <div class="col-12">
                    <div class="p-2">
                        <img class="m-auto" src="/assets/img/icons/dashboard/document.svg" height="40"
                            style="width: auto">
                        <span class="fs-16 fw-700 d-block mt-2 text-center"> Convenzione <br> Eutalia - CSLLPP </span>
                    </div>
                </div>
            </div>
        </div>

        <table class="table projects-table mt-5 mb-5" aria-describedby="project data table">
            <thead>
                <tr valign="middle">
                    <ng-container *ngFor="let column of columnsDatatable">
                        <th class="col archives-card">
                            <div class="col-content">
                                <span class="col-text"> {{column}} </span>

                                <div class="svg-wrapper">

                                </div>
                            </div>
                        </th>
                    </ng-container>
                </tr>

            </thead>
            <tbody>
                <tr *ngFor="let data of dataSources">

                    <!-- table station -->
                    <ng-container>
                        <th class="data">
                            <a target="_blank" href="{{data.name.url}}">{{data.name.text}}</a>
                        </th>
                        <th class="data">
                            <a target="_blank" href="{{data.main.url}}">{{data.main.text}}</a>
                        </th>
                        <th class="data">
                            <a target="_blank" href="{{data.work.url}}">{{data.work.text}}</a>
                        </th>
                    </ng-container>
                    <!-- end: generate value for row columns -->
                </tr>
            </tbody>
        </table>
    </div>

</div>
