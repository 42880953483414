<section *ngIf="showDirectory" id="list-document-title-section">
	<label class="fs-24 fw-700">{{"DOC_LABEL" | translate}}</label>
</section>

<!-- begin: list directory -->
<ng-container *ngIf="showDirectory">
	<ng-container *ngIf="statusProject; else noDirectory">
		<ng-container *ngFor="let documentType of (documentsList | async); let i = index">
			<ng-container *ngIf="documentType.Directory.Approved != 0">
				<!-- begin: documentType section -->

				<section class="row" id="document-type-row-{{i}}">
					<!-- begin: documentType icon -->
					<div [ngClass]="userRole != roles.COMMITTEE ? 'col-6 m-2 d-flex' : 'col-12 p-2 m-2 d-flex'">
						<ng-container *ngIf="userRole != roles.COMMITTEE; else defaultIcon">
							<svg class="icon icon-sm icon-primary align-self-center mt-auto mb-auto mt-2" height="18">
								<ng-container [ngSwitch]="documentType.Directory.Approved">
									<ng-container *ngSwitchCase="statusUpload.ACCEPT">
										<ng-container
											*ngIf="userRole == roles.STATION && !documentType.Directory.Draft">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-check-circle"
												style="fill: #008055"></use>
										</ng-container>
										<ng-container *ngIf="userRole == roles.COUNCIL">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-check-circle"
												style="fill: #008055"></use>
										</ng-container>
									</ng-container>

									<ng-container *ngSwitchCase="statusUpload.REJECT">
										<ng-container
											*ngIf="userRole == roles.STATION && !documentType.Directory.Draft">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-close-circle"
												style="fill: #CC334D"></use>
										</ng-container>
										<ng-container *ngIf="userRole == roles.COUNCIL">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-close-circle"
												style="fill: #CC334D"></use>
										</ng-container>
									</ng-container>

									<ng-container *ngSwitchCase="statusUpload.WAIT">
										<ng-container
											*ngIf="userRole == roles.STATION && !documentType.Directory.Draft">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-help-circle"
												style="fill: #CC7A00"></use>
										</ng-container>
										<ng-container *ngIf="userRole == roles.COUNCIL">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-help-circle"
												style="fill: #CC7A00"></use>
										</ng-container>
									</ng-container>

									<!-- Aggiungi un caso predefinito se necessario -->
									<ng-container>
										<ng-container
											*ngIf="userRole === roles.STATION && documentType.Directory.Draft">
											<use href="assets/bootstrap-italia/svg/sprites.svg#it-help-circle"
												style="fill: #CC7A00"></use>
										</ng-container>
									</ng-container>
								</ng-container>
							</svg>

						</ng-container>

						<ng-template #defaultIcon>
							<svg class="icon icon-sm icon-primary align-self-center" height="18">
								<use href="assets/bootstrap-italia/svg/sprites.svg#it-folder"
									style="fill: var(--color-primary)"></use>
							</svg>
						</ng-template>
						<!-- end: documentType icon -->
						<!-- begin: documentType label -->
						<label class="ms-2 m-auto fs-16 fw-700">
							<a [href]="documentType.Url" target="_blank">{{documentType.Name}}</a>
						</label>

						<!-- end: documentType label -->
					</div>

					<div class="col-5">
						<!-- begin: documentType documetns list -->
						<ng-container>
							<div class="align-items-center py-2">
								<ng-container [ngSwitch]="userRole">
									<ng-container *ngSwitchCase="roles.STATION">
										<ng-container
											*ngTemplateOutlet="stationDocumentActionButton; context: {documentType: documentType, project: documentType}"></ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="roles.COMMITTEE">

									</ng-container>
									<ng-container *ngSwitchCase="roles.COUNCIL">
										<ng-container
											*ngTemplateOutlet="councilDocumentActionButton; context: {documentType: documentType}"></ng-container>
									</ng-container>
								</ng-container>
							</div>

						</ng-container>


						<!-- end: documentType documetns list -->
					</div>

					<ng-container *ngIf="documentType.Directory.Approved == statusUpload.REJECT">
						<div class="col-12">
							<div class="d-flex row">
								<div class="reject-note-box d-flex row">
									<ng-container
										*ngIf="(!documentType.Directory.Draft && userRole != roles.COUNCIL) || (!documentType.Directory.Draft && userRole == roles.COUNCIL)">
									<label class="fs-16 fw-700 col-12">{{"REJECT_NOTE_LABEL" | translate}}: </label>
										<p class="col-12">{{documentType.Directory.Note}}</p>
									</ng-container>
									<ng-container *ngIf="(documentType.Directory.Draft && userRole == roles.COUNCIL)">
										<textarea class="text-area width-area" [(ngModel)]="documentType.Directory.Note"
											(ngModelChange)="saveNota(documentType)"
											placeholder="Breve nota per la rettifica del documento" rows="2"></textarea>
									</ng-container>
								</div>
							</div>
						</div>
					</ng-container>
					<!-- <ng-container
					*ngIf="documentType.Directory.Approved == statusUpload.REJECT && userRole == roles.STATION">
					<div class="p-2"> -->
					<!-- TODO: inserire condizione nota vuota -->
					<!-- <ng-container
							*ngIf="documentType.Directory.Note && documentType.Directory.Note != '' && !documentType.Directory.Draft">
							<label class="fs-16 fw-700">{{"REJECT_NOTE_LABEL" | translate}}</label>
							<p class="reject-note-box">{{documentType.Directory.Note}}</p>
						</ng-container>
					</div>
				</ng-container> -->
				</section>
				<!-- end: documentType section -->

			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
<!-- end: end directory -->

<!-- begin: no list directory -->
<ng-template #noDirectory>
	<h4 style="color: var(--color-error)" class="fs-16 fw-bold mt-4 mb-4">
		{{ "NO_DOCUMENTS_LIST" | translate }}</h4>
</ng-template>
<!-- end: no list directory -->

<!-- begin: template for council with button -->
<ng-template #councilDocumentActionButton let-item="documentType">
	<ng-container *ngIf="item.Directory.Draft">
		<div class="d-flex justify-content-between">
			<button class="btn btn-sm"
				[ngClass]="item.Directory.Approved == statusUpload.WAIT ? 'btn-outline-primary' : item.Directory.Approved == statusUpload.ACCEPT ? 'btn-outline-secondary' : 'btn-danger'"
				type="button" (click)="changeStatusDocuments(item, true, 3);">
				{{ "REJECT" | translate }}
			</button>
			<button class="btn btn-sm" [ngClass]="item.Directory.Approved == statusUpload.WAIT ? 'btn-outline-primary' :
								item.Directory.Approved == statusUpload.REJECT ? 'btn-outline-secondary' : 'btn-success'" type="button"
				(click)="changeStatusDocuments(item, false, 2);">
				{{ "APPROVED" | translate }}
			</button>
		</div>
	</ng-container>
	<ng-container *ngIf="!item.Directory.Draft">
		<ng-container [ngSwitch]="item.Directory.Approved">
			<ng-container *ngSwitchCase="statusUpload.ACCEPT">
				<label class="text-success fs-16 fw-bold">{{"STATUS_ACCEPTED" | translate | uppercase}}</label>
			</ng-container>
			<ng-container *ngSwitchCase="statusUpload.REJECT">
				<label class="text-danger fs-16 fw-bold">{{"STATUS_REJECTED" | translate | uppercase}}</label>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>
<!-- end: template for council with button -->

<!-- begin: station show directory approved/not approved -->
<ng-template #stationDocumentActionButton let-item="documentType" let-id="project">
	<ng-container *ngIf="item.Directory.Draft; else noDraft">
		<div class="col-6 d-flex">
			<span class="d-flex fw-600 color-pending"> {{"STATUS_PENDING" | translate}} </span>
		</div>
	</ng-container>
	<ng-template #noDraft>
		<ng-container [ngSwitch]="item.Directory.Approved">
			<ng-container *ngSwitchCase="statusUpload.ACCEPT">
				<div class="col-12 d-flex">
					<label class="text-success fs-16 fw-bold">{{"STATUS_ACCEPTED" | translate | uppercase}}</label>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="statusUpload.REJECT">
				<section class="col-12 d-flex justify-content-between">
					<div class="col-6 align-self-center">
						<label class="text-danger fs-16 fw-bold">{{"STATUS_REJECTED" | translate | uppercase}}</label>
					</div>
					<div class="col-6">
						<button class="btn btn-sm btn-danger" type="button" (click)="openWindowUpload();">
							{{ "UPLOAD" | translate }}
						</button>
					</div>
				</section>
			</ng-container>
		</ng-container>
	</ng-template>
</ng-template>
<!-- end: station show directory approved/not approved -->

<!-- begin: list documents integration -->
<ng-container *ngIf="!showDirectory">
	<hr>
	<section class="row d-flex justify-content-between flex-row-reverse" id="list-document-title-section">
		<div class="col-5">
			<section id="list-document-title-section">
				<label class="fs-24 fw-700 mt-4">Documenti - Comitato Speciale </label>
			</section>
			<ng-container *ngFor="let doc of documentsIntegration?.DocumentsCouncil?.Documents">
				<div class="row">
					<div [ngClass]="userRole != roles.COMMITTEE ? 'm-2' : 'm-2 p-2'">
						<label class="d-block mt-2 mb-2 m-auto fs-16 fw-700">
							<svg class="icon icon-sm icon-primary align-self-center" height="18">
								<use href="assets/bootstrap-italia/svg/sprites.svg#it-file"></use>
							</svg>
							<a [href]="doc.Uri" target="_blank">{{doc.Name}} </a></label>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="documentsIntegration?.DocumentsCouncil?.Documents?.length==0">
				<h4 style="color: var(--color-primary)" class="fs-14 mt-4 mb-4">
					{{"NO_DOCUMENT_FOUND" | translate}} </h4>
			</ng-container>
		</div>
		<div class="col-7">
			<section id="list-document-title-section">
				<label class="fs-24 fw-700 mt-4">Documenti - Stazione Appaltante </label>
			</section>
			<ng-container *ngFor="let doc of documentsIntegration?.DocumentsStation?.Documents">
				<div class="row">
					<div [ngClass]="userRole != roles.COMMITTEE ? 'm-2' : 'm-2 p-2'">
						<label class="d-block mt-2 mb-2 m-auto fs-16 fw-700">
							<svg class="icon icon-sm icon-primary align-self-center" height="18">
								<use href="assets/bootstrap-italia/svg/sprites.svg#it-file"></use>
							</svg>
							<a [href]="doc.Uri" target="_blank">{{doc.Name}} </a></label>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="documentsIntegration?.DocumentsStation?.Documents?.length==0">
				<h4 style="color: var(--color-primary)" class="fs-14 mt-4 mb-4">
					{{"NO_DOCUMENT_FOUND" | translate}} </h4>
			</ng-container>
		</div>

	</section>
	
</ng-container>
<!-- end: list documents integration -->

<!-- begin: button enable inoltro when all documents result -->
<ng-container *ngIf="showDirectory">
<button *ngIf="(userRole == roles.COUNCIL && documentInDraft)" class="btn btn-primary btn-sm mt-4 col-12 pb-2"
	[disabled]="enableInoltro" (click)="openModal();">
	{{"UPDATE_DOCUMENTS" | translate}}
</button>
</ng-container>
<!-- end: button enable inoltro when all documents result -->