export class HttpContextConfig {
  noSpinner?: boolean;
  isPublic?: boolean;
  isInitializer?: boolean

  constructor() {
    this.noSpinner = false;
    this.isPublic = false;
    this.isInitializer = false;
  }
}

