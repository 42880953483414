<div class="it-header-center-wrapper it-small-header">
  <div class="container">
    <div class="row">
      <div class="col">
        <a class="marchio-ist col-auto" [href]="homePath()">
          <img class="icon" aria-hidden="true" src="assets/img/logos/marchio_ist.svg" alt="marchio-istituzionale"/>
          <div class="brand-text">
            <div>Consiglio Superiore</div>
            <div>dei Lavori Pubblici</div>
          </div>
        </a>
      </div>
      <div class="col d-flex justify-content-center">
        <a class="nav-link cursor-pointer" (click)="navigateTo(homePath())" *ngIf="homeIcon()">
          <svg class="icon icon-white">
            <use href="assets/bootstrap-italia/svg/sprites.svg#it-pa"></use>
          </svg>
        </a>
      </div>
      <div class="col d-flex flex-row-reverse">
        <ng-container *ngIf="profileIcon()">
          <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false" id="mainNavDropdownC1">
            <img class="icon" aria-hidden="true" src="assets/img/icons/profile_icon.svg" alt="profile-icon">
          </a>
          <div class="dropdown-menu" role="region" aria-labelledby="mainNavDropdownC1">
            <div class="link-list-wrapper">
              <ul class="link-list">
                <ng-container *ngIf="loadedForm$ | async"> 
                <li *ngIf="userRole == roles.COUNCIL">
                  <a class="dropdown-item list-item cursor-pointer" (click)="navigateTo('council/admin')"><span>{{"ADMINISTRATOR.ADMIN_LABEL" | translate}}</span></a>
                </li>
              </ng-container>
              <li><a class="dropdown-item list-item cursor-pointer" (click)="logout()"><span>{{"LOGOUT_LABEL" | translate}}</span></a></li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row p-0 flag-container">
    <div class="col-4 flag-green"></div>
    <div class="col-4 flag-white"></div>
    <div class="col-4 flag-red"></div>
  </div>
</div>
