<ng-container>
	<div class="container mt-2 pb-2">
		<div class="col-10 mt-5 m-auto">
			<a (click)="backToPage()" class="d-flex">
				<svg class="align-self-center icon icon-xs icon-primary">
					<use href="assets/bootstrap-italia/svg/sprites.svg#it-arrow-left"></use>
				</svg>
				<label class="ms-1 fs-16">
					{{"BACK" | translate}}
				</label>
			</a>
			<h3 class="fs-24 fw-700 mt-5"> Nome del progetto </h3>
			<h6 class="fs-24 fw-400"> {{infoProjects?.Name}} </h6>
			<hr>
		</div>
	</div>
</ng-container>

<!-- begin: Mandatory docs -->
<ng-container *ngIf="mandatoryDocType && mandatoryDocType.length > 0">
	<div class="container pb-2">
		<div class="col-10 m-auto">
			<div class="d-flex row pb-2">
				<div class="row ">
					<label class="col-9 fs-24 fw-700">
						{{ "MANDATORY_DOC_LABEL" | translate }} *
					</label>
				</div>
				<ng-container *ngFor="let doc of mandatoryDocType, let i = index">
					<ng-template [ngTemplateOutlet]="docrow"
						[ngTemplateOutletContext]="{doc: doc, i: i, mandatory: true, filearray: []}">
					</ng-template>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>
<!-- end: Mandatory docs -->

<!-- begin: Optional docs -->
<ng-container *ngIf="optionalDocType && optionalDocType.length > 0">
	<div class="container pb-2">
		<div class="col-10 m-auto">
			<div class="d-flex row">
				<div class="row ">
					<label class="col-9 fs-24 fw-700">
						{{ "OPTIONAL_DOC_LABEL" | translate }}
					</label>
				</div>
				<ng-container *ngFor="let doc of optionalDocType, let i = index">
					<ng-template [ngTemplateOutlet]="docrow"
						[ngTemplateOutletContext]="{doc: doc, i: i, mandatory: false, filearray:  []}">
					</ng-template>
				</ng-container>
			</div>
		</div>

		<!-- <div class="container">
			<div class="col-10 m-auto">
				<div class="d-flex justify-content-between mb-5">
					<button class="col-12 btn btn-primary btn-sm mt-4 mb-4" (click)="onSubmit();">
						{{"CONTINUE_LABEL" |
						translate }} </button>
				</div>
			</div>
		</div> -->
	</div>
</ng-container>
<!-- end: Optional docs -->

<!-- begin: Integration docs -->
<ng-container *ngIf="integrationDoc.length > 0">
	<div class="container">
		<div class="d-flex m-auto col-10 row">
			<div class="row py-4">
				<label class="col-9 fs-24 fw-700">
					{{ "MANDATORY_DOC_LABEL" | translate }}
				</label>
				<div class="col-3 d-flex flex-row-reverse">
				</div>
			</div>

			<ng-container *ngFor="let item of integrationDoc">
				<ng-template [ngTemplateOutlet]="integration"
					[ngTemplateOutletContext]="{item: item, filearray: getFilteredIntegrationsFiles(item.Id)?.Files}"></ng-template>
			</ng-container>
		</div>
	</div>
</ng-container>
<!-- end: Integration docs -->

<div class="container">
	<div class="col-10 mt-4 m-auto">
		<div class="d-flex justify-content-between">
			<button class="col-12 btn btn-primary btn-sm mb-4" (click)="onSubmit();">
				{{"CONTINUE_LABEL" | translate }} </button>
		</div>
	</div>
</div>

<!-- begin: modal upload drag & drop -->
<div class="modal fade" tabindex="-1" role="dialog" id="uploadModal" aria-labelledby="Drag&Drop Modal"
	data-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title h5 no_toc" id="dragDropModalSection">{{"INPUT_FILE" | translate }}</h3>
				<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Chiudi finestra modale">
					<svg class="icon">
						<use href="assets/bootstrap-italia/svg/sprites.svg#it-close"></use>
					</svg>
				</button>
			</div>
			<div class="modal-body text-center">
				<form class="upload-dragdrop" method="post" action="" enctype="multipart/form-data"
					id="uploadChangeStateTarget" data-bs-upload-dragdrop>

					<!-- begin: modal img left in upload -->
					<div class="upload-dragdrop-image align-self-baseline">
						<img src="/assets/bootstrap-italia/assets/upload-drag-drop-icon.svg" alt="descrizione immagine"
							aria-hidden="true">
					</div>
					<!-- end: modal img left in upload -->

					<div class="upload-dragdrop-text">
						<div class="center">
							<!-- begin: drag & drop file -->
							<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="handleFiles($event)">
								<ng-template ngx-file-drop-content-tmp>
									<h6 id="simTitle">{{"DRAG_THE_FILE_TO_UPLOAD" | translate}}</h6>
								</ng-template>
							</ngx-file-drop>
							<!-- begin: end & drop file -->

							<p id="simText" class="mt-2">{{"OR" | translate}}
								<input type="file" name="upload8" id="upload8" #inputFile
									(change)="handleFileDevice($event.target, inputFile)" class="upload-dragdrop-input"
									[accept]="documentId?.Extension" multiple />
								<label for="upload8">{{"SELECT_FROM_DEVICE" | translate}}</label>
							</p>

							<div class="upload-table">
								<ng-container *ngFor="let file of tempFilesDropped">
									<div class="row mt-2">
										<div
											class="upload-name-style col-6 d-flex ms-2 align-items-center hover-container">
											<svg class="icon icon-sm icon-success align-self-center suppress-icon"
												height="18">
												<use href="assets/bootstrap-italia/svg/sprites.svg#it-check-circle">
												</use>
											</svg>
											<button class="d-none btn bg-transparent btn-sm p-0 btn-remove"
												(click)="removeFile(file, tempFilesDropped)">
												<svg class="icon icon-sm icon-danger align-self-center" height="18">
													<use href="assets/bootstrap-italia/svg/sprites.svg#it-close-circle">
													</use>
												</svg>
											</button>
											<span class="text-truncate">{{file.name}}</span>
										</div>
										<div class="file-size col-5">
											<span>{{file.size}}</span>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</div>

					<input value="file" type="file" class="d-none" [accept]="documentId?.Extension" />
				</form>
			</div>
			<div class="modal-footer">
				<button class="d-none" data-bs-dismiss="modal" id="closeModalButton" #closeModalButton></button>
				<button class="btn btn-primary btn-sm" type="button"
					(click)="submitModalv2(); closeModalButton.click()">
					{{"UPLOAD" | translate}}
				</button>
			</div>
		</div>
	</div>
</div>
<!-- end: modal upload drag & drop -->

<!-- begin: DocRow Template -->
<ng-template #docrow let-doc="doc" let-i="i" let-mandatory="mandatory" let-filearray="filearray">
	<div class="row d-flex flex-row mt-2 border-bottom pb-2">
		<div class="col-5 d-flex">
			<span class="align-self-center d-flex">
				<svg class="icon icon-sm icon-primary align-self-center" height="18">
					<use href="assets/bootstrap-italia/svg/sprites.svg#it-info-circle"></use>
				</svg>
			</span>
			<div class="col-12 ms-1 row">
				<label class="align-self-center col-12 fs-16">{{doc.Name}}</label>
				<ng-container *ngIf="doc.description">
					<label class="align-self-center col-12 fs-16"><i>{{doc.Description}}</i></label>
				</ng-container>
			</div>
		</div>

		<div class="col-4 my-auto">
			<div class="col-12">
				<ng-container *ngFor="let filename of filearray">
					<ng-template [ngTemplateOutlet]="fileinfo"
						[ngTemplateOutletContext]="{filename: filename, array: filearray}"></ng-template>
				</ng-container>
			</div>
		</div>

		<div class="col-3 d-flex flex-row-reverse align-items-center">
			<button class="btn btn-primary btn-upload" (click)="goToUpload(doc.Url)">
				<svg class="icon icon-white">
					<use href="assets/bootstrap-italia/svg/sprites.svg#it-upload"></use>
				</svg>
				<label class="text-white">{{"UPLOAD" | translate}}</label>
			</button>
		</div>
	</div>
</ng-template>
<!-- end: DocRow Template -->

<!-- begin: Fileinfo Template -->
<ng-template #fileinfo let-filename="filename" let-array="array" let-id="id">
	<div class="d-flex flex-row fs-16 justify-content-between">
		<div class="col-7 text-truncate">
			<svg class="icon icon-sm icon-primary align-self-center" height="18">
				<use href="assets/bootstrap-italia/svg/sprites.svg#it-files"></use>
			</svg>
			<span> {{filename.name}} </span>
		</div>
		<div class="col-3 text-truncate">
			<span class="fs-14 ms-5 fw-700">{{filename.size}}</span>
		</div>
		<div class="col-2">
			<button class="btn p-0 bg-transparent" type="button" data-bs-toggle="tooltip" data-bs-placement="top"
				title="Rimuovi" data-bs-container="body" data-bs-animation="true"
				data-bs-delay='{"show":"1000", "hide":"100"}' (click)="removeFile(filename, array)"
				style="position: relative; z-index: 20">
				<svg class="icon icon-sm icon-danger bg-white circle-icon" height="18">
					<use href="assets/bootstrap-italia/svg/sprites.svg#it-close-circle" style="fill: #CC334D"></use>
				</svg>
			</button>
		</div>
	</div>
</ng-template>

<!-- end: Fileinfo Template -->

<!-- begin: integrations file template -->
<ng-template #integration let-item="item" let-filearray="filearray">
	<div class="row d-flex flex-row mt-2 pb-2">
		<div class="col-5 d-flex">
			<svg height="18" class="icon icon-sm icon-primary align-self-center">
				<use href="assets/bootstrap-italia/svg/sprites.svg#it-files" class="fs-12"></use>
			</svg>

			<label class="align-self-center fs-16 ms-2">{{item.Name}}</label>
		</div>
		<div class="col-5">
			<ng-container *ngFor="let filename of filearray">
				<ng-template [ngTemplateOutlet]="fileinfo"
					[ngTemplateOutletContext]="{filename: filename, array: filearray}"></ng-template>
			</ng-container>
			<ng-container *ngIf="!filearray && requiredError">
				<label class="text-danger"> File mancanti </label>
			</ng-container>
		</div>
		<div class="col-2 d-flex flex-row-reverse">
			<button class="btn btn-primary btn-upload" (click)="setDocumentID(item)" data-bs-toggle="modal"
				data-bs-target="#uploadModal">
				<svg class="icon icon-white">
					<use href="assets/bootstrap-italia/svg/sprites.svg#it-upload"></use>
				</svg>
				<label class="fw-700 text-white">{{"UPLOAD" | translate}}</label>
			</button>
		</div>

	</div>
	<hr>
</ng-template>
<!-- end: integrations file template -->