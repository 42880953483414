import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-archive-files',
  templateUrl: './archive-files.component.html',
  styleUrls: ['./archive-files.component.scss']
})
export class ArchiveFilesComponent implements OnInit {
  columnsDatatable = ["EUTALIA SRL", "CSLLPP", "Gruppo di lavoro"]
  councilInfo: any;
  roles = Role;
  dataSources = [
    {
      name: {
        text: "Contrattualistica",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_1}/Contrattualistica`
      },
      main: {
        text: "RUP",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_2}/RUP`
      },
      work: {
        text: "Cartelle componenti GdL",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_3}/(20%20cartelle%20per%20componenti%20GdL)`
      }
    },
    {
      name: {
        text: "Corrispondenza Eutalia - MIT",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_1}/Corrispondenza%20Eutalia%20-%20MIT`
      },
      main: {
        text: "DEC",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_2}/DEC`
      },
      work: {
        text: "Cartella posta di appoggio",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_3}/Cartella%20posta%20di%20appoggio`
      }
    },
    {
      name: {
        text: "SAL",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_1}/SAL`
      },
      main: {
        text: "Coordinatore tecnico amministrativo GdL",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_2}/Coordinatore%20tecnico%20amministrativo%20GdL`
      },
      work: {
        text: "Documentazione/format utile",
        url: `${environment.nextcloud.url}${environment.nextcloud.main_directory}${environment.nextcloud.group_directory.dir_3}/Documentazione-format%20utile`
      }
    }
  ];

  constructor(private authService: AuthService, private router: Router) {
    this.authService.currentUser$.subscribe(
      (user) => {
        this.councilInfo = user;
      }
    )
  }

  ngOnInit(): void {
  }

  goToUrl() {
    window.open(environment.nextcloud.url, '_blank');
  }

}