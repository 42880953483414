import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Station } from '../models/station';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  getStations(page_number: any, page_size: any, order: any, search: any): Observable<any> {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/stationsaccount?pageNumber=${page_number}&pageSize=${page_size}&search=${search}&order=${order}`);
  }

  createStation(data: Station): Observable<any> {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/stationsaccount`, data);
  }

  getUser(page_number: number, page_size: number, order: any, search: any): Observable<any> {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users?pageNumber=${page_number}&pageSize=${page_size}&order=${order}&search=${search}`);
  }

  createUser(data: any) {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/auth/register`, data)
  }

  updateUser(user_id: string, data: any) {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users/${user_id}`, data)
  }

  disableUser(user_id: string, data: any) {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users/${user_id}/disable`, data)
  }

  updateStation(station_id: string, data: any) {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/stationsaccount/${station_id}`, data);
  }

  getCommitteGroups() {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users/committee`);
  }




}
