import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Breadcrumb} from "./models/breadcrumb";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);
  constructor() { }

  get breadcrumbsArray(): Breadcrumb[] {
    return this.breadcrumbs.getValue();
  }

  set breadcrumbsArray(breadcrumbs: Breadcrumb[]){
    this.breadcrumbs.next(breadcrumbs);
  }

  ngOnInit(): void {
  }

}
