import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Paginaiton} from "./models/paginaiton";
import {BehaviorSubject, distinctUntilChanged} from "rxjs";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  /**
   * Maintains the pagination status
   * @private
   * @type {BehaviorSubject<Paginaiton>}
   */
  @Input() pagination: BehaviorSubject<Paginaiton> = new BehaviorSubject<Paginaiton>({
    currentPage: 1,
    perPage: 0,
    totalItems: 0,
  });

  /**
   * Maintains the pages to be displayed
   * @private
   */
  private pages: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);


  /**
   * Option of per page to display
   * @type {number[]}
   * @memberof PaginatorComponent
   */
  @Input() perPageOptions: number[] = [10, 20, 50, 100];

  /**
   * Number of total elements if paginator element is not passed
   */
  @Input() totalElements: number = 0;

  /**
   * Emits when any of the pagination buttons is clicked
   * @type {EventEmitter<Paginaiton>}
   * @memberof PaginatorComponent
   * @example
   * <app-paginator (pageChanged)="onPageChanged($event)"></app-paginator>
   */
  @Output() pageChanged: EventEmitter<Paginaiton> = new EventEmitter<Paginaiton>();

  /**
   * Check whether the next button should be clickable
   * @private
   * @returns {boolean}
   * @memberof PaginatorComponent
   */
  private enableNext(): boolean {
    return this.paginationValue.currentPage < Math.ceil(this.paginationValue.totalItems / this.paginationValue.perPage);
  }

  /**
   * Check whether the prev button should be clickable
   * @private
   * @returns {boolean}
   * @memberof PaginatorComponent
   */
  private enablePrev(): boolean {
    return this.paginationValue.currentPage > 1;
  }

  /**
   * Check whether the first button should be clickable
   * @private
   * @returns {boolean}
   * @memberof PaginatorComponent
   */
  private enableFirst(): boolean {
    return this.paginationValue.currentPage > 2;
  }

  /**
   * Check whether the last button should be clickable
   * @private
   * @returns {boolean}
   * @memberof PaginatorComponent
   */
  private enableLast(): boolean {
    return this.paginationValue.currentPage < Math.ceil(this.paginationValue.totalItems / this.paginationValue.perPage) - 1;
  }

  /**
   * Returns the array pages to be displayed
   * @param value
   * @private
   */
  private getPages(value: Paginaiton): number[] {
    let pages: number[] = [this.paginationValue.currentPage];
    if(value.currentPage > 1){
      pages = [value.currentPage - 1, ...pages];
      if(value.currentPage > 2) pages = [value.currentPage - 2, ...pages];
    }
    if(value.totalItems && value.currentPage < value.totalItems / value.perPage){
      pages = [...pages, value.currentPage + 1];
      if(value.currentPage < value.totalItems / value.perPage - 1) pages = [...pages, value.currentPage + 2];
    }
    return pages;
  }

  constructor() { }

  ngOnInit(): void {

    // Check if the total elemnts is passed
    // The control is separate from the next one because the entire paging element might be expected as input
    if(this.totalElements !== 0){
      this.paginationValue = {
        ...this.paginationValue,
        totalItems: this.totalElements
      }
    }

    // Check if the per page options is passed
    if(this.paginationValue.totalItems == 0){
      console.error(`🚨 Pagination Error: Total Item must be set!`)
    }

    if(this.paginationValue.perPage == 0){
      this.paginationValue = {
        ...this.paginationValue,
        perPage: this.perPageOptions[0]
      }
    }

    if(this.paginationValue.currentPage > Math.ceil(this.paginationValue.totalItems / this.paginationValue.perPage)){
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: Math.ceil(this.paginationValue.totalItems / this.paginationValue.perPage)
      }
    }

    // On whether change of pagination value update the pages
    this.pagination.pipe(distinctUntilChanged())
      .subscribe((value: Paginaiton) => {
      this.pagesValue = this.getPages(value);
      });
  }

  /**
   * Return the disabled style for first, prev, next and last buttons
   * @param buttonType
   */
  getClassOfButton(buttonType: 'prev' | 'next' | 'last' | 'first'){
    switch (buttonType) {
      case 'prev':
        return this.enablePrev() ? '' : 'disabled';
      case 'next':
        return this.enableNext() ? '' : 'disabled';
      case 'last':
        return this.enableLast() ? '' : 'disabled';
      case 'first':
        return this.enableFirst() ? '' : 'disabled';
    }

    return ``;
  }

  /**
   * Return the class active for page active
   * @param page
   */
  getClassOfPage(page: number): string {
    return page === this.paginationValue.currentPage ? 'active' : '';
  }

  /**
   * Get current pagination value
   */
  get paginationValue(): Paginaiton {
    return this.pagination.value;
  }

  /**
   * Set the pagination value
   * @param value {Paginaiton}
   */
  set paginationValue(value: Paginaiton) {
    this.pagination.next(value);
  }

  /**
   * Get the page to display
   */
  get pagesValue(): number[] {
    return this.pages.value;
  }

  /**
   * Set the page to display
   * @param value
   */
  set pagesValue(value: number[]) {
    this.pages.next(value);
  }

  /**
   * Set the next page and Emit the pageChanged event
   */
  next(): void {
    if(this.paginationValue.currentPage < Math.ceil(this.paginationValue.totalItems / this.paginationValue.perPage)){
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: this.paginationValue.currentPage + 1
      }
      this.pageChanged.emit(this.paginationValue);
    }
  }

  /**
   * Set the previous page and Emit the pageChanged event
   */
  prev(): void {
    if(this.paginationValue.currentPage > 1){
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: this.paginationValue.currentPage - 1
      }
      this.pageChanged.emit(this.paginationValue);
    }
  }

  /**
   * Set the first page and Emit the pageChanged event
   */
  first(): void {
    if(this.paginationValue.currentPage > 1){
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: 1
      }
      this.pageChanged.emit(this.paginationValue);
    }
  }

  /**
   * Set the last page and Emit the pageChanged event
   */
  last(): void {
    if(this.paginationValue.currentPage < Math.ceil(this.paginationValue.totalItems / this.paginationValue.perPage)){
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: this.paginationValue.totalItems / this.paginationValue.perPage
      }
      this.pageChanged.emit(this.paginationValue);
    }
  }

  /**
   * Set the page clicked and Emit the pageChanged event
   */
  goToPage(page: number): void {
    if(page !== this.paginationValue.currentPage) {
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: page
      }
      this.pageChanged.emit(this.paginationValue);
    }
  }

  /**
   * Change the number of items per page and Emit the pageChanged event
   * @param perPage
   */
  changePerPage(perPage: number): void {
    if(perPage !== this.paginationValue.perPage){
      this.paginationValue = {
        ...this.paginationValue,
        currentPage: 1,
        perPage: perPage
      }
      this.pageChanged.emit(this.paginationValue);
    }
  }
}
