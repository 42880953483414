import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { toast } from 'src/swal';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from "moment";
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { Role } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';




@Component({
  selector: 'app-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.scss']
})
export class FullCalendarComponent implements OnInit {

  @Output() openModalChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() openModalDetail: EventEmitter<any> = new EventEmitter<any>();

  events: boolean = false;
  /**
  * Contains all the events calendar
  * @type {[]}
  */
  calendarEvent = new Array({});

  obj: any;
  /**
  * Used for initializing the calendar
  * @type {CalendarOptions}
  */
  calendarOptions!: CalendarOptions;

  role = Role;

  userRole: Role | undefined = this.authService.currentUserValue?.role;

  constructor(private calendar: CalendarService, private authService: AuthService) {
  }

  ngOnInit(): void {
    // used for refresh data
    this.calendar.getRefreshData().subscribe(value => {
      this.getEvents();
      this.getCalendar();
    });
    if (this.userRole === this.role.COMMITTEE) {
      const styleElement = document.createElement('style');
      styleElement.innerText = '.fc-daygrid-day-top:hover::after { display: none; }';
      document.head.appendChild(styleElement);
    }
    this.getEvents();
    this.getCalendar();
  }

  handleClick(info: any) {
    let todayDate = moment().format('YYYY-MM-DD');
    let date = moment(info.date);
    // se serve il controllo sulle date abilitare
    if (this.userRole == this.role.COUNCIL) {
      if (moment(todayDate).isBefore(date)) {
        let formattedTime = date.format('YYYY-MM-DD');
        this.openModalChange.emit(formattedTime);
      } else {
        toast.fire({
          text: 'La data selezionata non è disponibile',
          title: '',
          icon: 'warning'
        })
      }
    }
  }

  getCalendar() {
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      dateClick: (info) => { this.handleClick(info) },
      plugins: [dayGridPlugin, interactionPlugin],
      timeZone: 'UTC',
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false
      },
      locale: 'it', // ? per impostare la lingua
      headerToolbar: {
        right: 'prev next',
        left: 'title'
      },
      events: this.calendarEvent,
      dayHeaderFormat: {
        weekday: 'narrow',
      },
      firstDay: 1,
      titleFormat: {
        year: 'numeric', month: 'long'
      },
      eventClick: (info) => {
        this.openModalDetail.emit(info)
      },
    };
  }

  getEvents() {
    this.calendar.getEvents().subscribe((res) => {
      this.calendarEvent = [];
      res.data.map((item: any) => {
        this.obj = {
          title: item.subject,
          description: {
            subject: item.subject,
            description: item.description,
            projectId: item.projectId,
            date: moment(item.date).format('YYYY-MM-DD'),
            startHour: item.startHour,
            endHour: item.endHour,
            meetingUrl: item.meetingUrl,
            place: item.place,
            id: item.id,
            users: item.users,
            isValid: item.isValid,
            otherEmails: item.otherEmails
          },
          start: item.date
        }
        this.calendarEvent.push(this.obj);
        this.events = true;
        this.getCalendar();
      }
      );
    });

  }

}

// swal fire custom class
const fire = toast.mixin({
  customClass: {
    confirmButton: 'btn btn-outline-primary m-2',
    cancelButton: 'btn btn-primary m-2'
  },
  buttonsStyling: false
});