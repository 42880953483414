import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, debounceTime } from 'rxjs';
import { Document, ListDocumentsFilter } from 'src/app/shared/models/document';
import { ResultUpload } from 'src/app/shared/models/document-type';
import { IProject } from 'src/app/shared/models/project';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { toast } from 'src/swal';
import Swal from 'sweetalert2';
import { Role } from "../../shared/models/user";
import { AuthService } from "../../shared/services/auth.service";
import { SingleFile } from '../doc-uploader/models/files-array';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.scss']
})
export class ListDocumentsComponent implements OnInit {

  @Input() showDirectory: boolean = false;
  @Output() refreshDocument: EventEmitter<any> = new EventEmitter<any>();
  @Input() projectStatus!: IProject;

  /**
  * Contains a list of documents
  * @type {BehaviorSubject<any[]>}
  * @Input
  */
  @Input() documentsList: BehaviorSubject<any> = new BehaviorSubject<any>({});

  @Input() documentsIntegration: ListDocumentsFilter | undefined;
  /**
  * Used for obtaining the project_id
  * @type {string}
  */
  project_id: string = this.activatedRoute.snapshot.params['projectId'];

  /**
  * Used for obtaining the role of interface
  * @type {Role}
  */
  roles = Role;

  /**
  * Used for obtaining the status of interface
  * @type {ResultUpload}
  */
  statusUpload = ResultUpload;

  /**
  * Used for obtaining the status of documents [accept, reject, pending]
  * @type {ResultUpload}
  */
  documentsStatus = ResultUpload;

  /**
  * Used for obtaining the id of directory 
  * @type {String}
  */
  documentId: string = '';

  userRole: Role | undefined;
  tempFilesDropped: SingleFile[] = [];
  documentsReupload = new Array();
  reject_nota: Subject<Document> = new Subject();

  constructor(private _project: ProjectsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService) {
    this.userRole = this.authService.currentUserValue?.role;

    this.reject_nota.pipe(debounceTime(500)).subscribe(
      (document: Document) => {
        // let title = "Sicuro di voler salvare?"
        // customSwal.fire({

        //   title: "<p class='fw-600 fs-24'>" + title + "</p>",
        //   showCancelButton: true,
        //   confirmButtonText: 'Conferma',
        //   cancelButtonText: 'Annulla',
        //   reverseButtons: true
        // }).then((result: any) => {
        // if (result.isConfirmed) {
        this._project.noteRejectDocuments(this.project_id, document.Directory.Id, document.Directory.Note).subscribe(
          (value) => {
            toast.fire({
              title: this.translateService.instant("TOAST.SUCCESS.SAVED"),
              text: this.translateService.instant("TOAST.SUCCESS.NOTE_SAVED"),
              icon: 'success'
            });
          })
        // }
        // });
      });
  }

  ngOnInit(): void {
  }

  openModal() {
    let title = "Sei sicuro di voler procedere?"
    fire.fire({
      title: "<p class='fw-600 fs-24'>" + title + "</p>",
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._project.submitDocuments(this.project_id).subscribe(
          (value) => {
            window.location.reload();
          }
        )
      }
    });
  };


  get enableInoltro(): boolean {
    return this.documentsList.value.some((el: any) => el.Directory.Approved == 1);
  }

  get documentInDraft(): boolean {
    return this.documentsList.value.some((el: any) => el.Directory.Approved != 0 && el.Directory.Draft);
  }

  changeStatusDocuments(list: any, note_status: boolean, statusDocuments: number) {
    this.documentId = list.Directory.Id;
    if (note_status == false) { document.getElementById('accept')?.classList.remove('d-none'); }
    this._project.changeStatusDocument(this.project_id, this.documentId, { approved: statusDocuments }).subscribe(
      (value) => {
        this.refreshDocument.emit();
      });
  }

  saveNota(doc: Document) {
    this.reject_nota.next(doc);
  }

  onSubmit() {
    this.tempFilesDropped = [];
  }

  uploadOrAvanceStep() {
    let title = "Sei sicuro di voler procedere?"
    fire.fire({
      title: "<p class='fw-600 fs-24'>" + title + "</p>",
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._project.reUploadDocuments(this.project_id, this.documentsReupload).subscribe(
          (res) => {
            this.refreshDocument.emit(true);
          }
        )
        this.tempFilesDropped = [];
      }
    });
  }


  checkAllDocuments(item: any): boolean {
    return item.some((doctype: any) => doctype.Directory.Approved == this.documentsStatus.ACCEPT);
  }


  get statusProject(): boolean {
    return this.documentsList.value.some((el: any) => el.Directory.Approved !== 0);
  }


  openWindowUpload() {
    this.router.navigate([`station/projects/upload/${this.project_id}`]);
  }

  navigateTo(url: string) {
    this.router.navigate([`station/projects/${this.project_id}/${url}`]);
  }
}

const fire = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-outline-primary m-2'
  },
  buttonsStyling: false
})
