import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Role } from "../../shared/models/user";
import { IProject } from "../../shared/models/project";
import { ProjectsService } from "../../shared/services/projects.service";
import { BehaviorSubject, forkJoin } from "rxjs";
import { ListDocumentsFilter, ProjectDocuments, Trash } from "../../shared/models/document";

@Component({
	selector: 'app-detail-project',
	templateUrl: './detail-project.component.html',
	styleUrls: ['./detail-project.component.scss']
})
export class DetailProjectComponent implements OnInit {
	projectId: string = this.activatedRoute.snapshot.params['projectId'];

	public breadcrumbs: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([
		{ link: this.router.url.split('/')[1], label: 'DASHBOARD.BREADCRUMB' },
		{ link: 'committee/' + this.projectId, label: 'PROJECT_LABEL' },
	]);

	roles = Role;
	user = this.authService.currentUserValue;

	projectDetails: IProject | null = null;
	documents: BehaviorSubject<ProjectDocuments[]> = new BehaviorSubject<ProjectDocuments[]>([]);

	noDirectory: boolean = false;
	documentsIntegration: ListDocumentsFilter | undefined = undefined;
	projectInfo!: IProject;
	documentsAll: any;
	allow_upload = ["nomina_commissione", "nomina_commissione_conferenza"];
	documentId: string = '';
	public timelineStep: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	constructor(
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private router: Router,
		private projectService: ProjectsService
	) { }

	ngOnInit(): void {
		localStorage.removeItem('redirect_url');
		this.getProjectInfo();
		this.getTimeLineInfo();
	}

	getTimeLineInfo() {
		this.projectService.getTimelineProject(this.projectId).subscribe(
			(res: any) => {
				this.timelineStep.next(res.Data);
			}
		)
	}

	getProjectInfo() {
		forkJoin({
			project: this.projectService.getProject(this.projectId),
			documents: this.projectService.getProjectDocument(this.projectId)
		}).subscribe({
			next: (response) => {
				if (response.project.Data.Button) { this.projectInfo = response.project.Data; }
				this.documentsIntegration = (response.documents.Data);
				this.projectDetails = response.project.Data;
				this.documentsAll = [
					...response.documents.Data.Directory.Required,
					...response.documents.Data.Directory.Optional,
				]
				this.arrayForUpload(this.projectDetails);
				this.documents = new BehaviorSubject<ProjectDocuments[]>(this.documentsAll);
			}

		});
	}

	arrayForUpload(event: any) {
		if (this.allow_upload.includes(event?.TaskId)) {
			this.projectService.getProjectUploadDocument(this.projectId).subscribe(
				(res: any) => {
					// protocollo nomina
					res.Data.Documents.filter((el: any) => { if (el.Code == 'nomina_commissione' || el.Code == 'nomina_commissione_conferenza') this.documentId = el.Id })
				}
			)
		}
	}

	goTo() {
		this.router.navigateByUrl(this.projectDetails?.Button.Url + '/' + this.projectId)
	}

}
