export enum StatusLabel {
    ISTRUTTORIA = "ISTRUTTORIA",
    PARERE_IN_LAVORAZIONE = "PARERE_IN_LAVORAZIONE",
    FINE_PROGETTO = "FINE_PROGETTO",
    PARERE_EMESSO = "PARERE_EMESSO",
    INTEGRAZIONE_CHIARIMENTI = "INTEGRAZIONE_CHIARIMENTI",
    INIZIO = "INIZIO",
    CONFERENZA_SERVIZI = "CONFERENZA_SERVIZI",
    BIS_5TER = "5BIS/5TER",
}

