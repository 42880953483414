import { Injectable } from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../models/user";
import {environment} from "../../../environments/environment";
import {tap} from "rxjs/operators";
import {FormattedResponse} from "../models/formatted-response";
import {CONFIG} from "../helpers/interceptor.service";
import {HttpContextConfig} from "../models/http-context-config";
import {AuthResponse} from "../../layout/auth/models/auth-response";
import { Router } from '@angular/router';

const AUTH_API_URL: string = `${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/auth`;
//const AUTH_API_URL: string = `http://localhost:8000/api/v1/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private redirectUrl: any | null = null;

  private currentUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public currentUser$: Observable<User | null> = this.currentUser.asObservable();
  constructor(private http: HttpClient,private router: Router) {
  }
  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }

  get currentUserValue(): User | null {
    return this.currentUser.value;
  }

  set currentUserValue(user: User | null) {
    this.currentUser.next(user);
  }
  
  logout() {
    if(environment.isDevMode){
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('id_token');
      localStorage.removeItem('redirect_url');
      this.currentUserValue = null;
      this.router.navigate(['/auth/login']);
      return;
    }
		let url = `${environment.wso2_identity_server.url}/oidc/logout` +
			`?post_logout_redirect_uri=${environment.wso2_identity_server.logout_callback_url}` +
			`&id_token_hint=${localStorage.getItem('id_token')}`;
		window.location.href = url;
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/users/me`, {context: context});
  }

	authenticate(code: string, feedIdp?: 'simple' | 'spid' | 'cie' | 'cns' | null): Observable<FormattedResponse<AuthResponse>> {
		const url = `${AUTH_API_URL}/authenticate`;
		let params = {
			code: code,
			redirect_uri: environment.wso2_identity_server.callback_url
		}

		if (feedIdp) Object.assign(params, { feedIdp: feedIdp })

		const context = new HttpContextConfig();
		context.isPublic = true;

		return this.http.post<FormattedResponse<AuthResponse>>(url, params, {context: new HttpContext().set(CONFIG, context)});
	}
}
